.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
    width: 20px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #4f0336; /* Change the background color */
    box-shadow: 0 0 0 0 #4f03363b; /* Change the shadow color and opacity */
    animation: l2 1.5s infinite linear;
    position: relative;
  }
  
  .loader:before,
  .loader:after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    box-shadow: 0 0 0 0 #4f03363b; /* Change the shadow color and opacity */
    animation: inherit;
    animation-delay: -0.5s;
  }
  
  .loader:after {
    animation-delay: -1s;
  }
  
  @keyframes l2 {
      100% {
          box-shadow: 0 0 0 40px #0000; /* Change the shadow color */
      }
  }
